import { Button, makeStyles } from '@material-ui/core'
import { NavigateBeforeSharp } from '@material-ui/icons'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import { flexbox } from '@material-ui/system'

import React from 'react'
interface IFooterProps {
  options?: any
  chipLabel?: string
  value?: string | number | any
  single?: Boolean
  isHidden?: boolean
  totalAmt?: boolean
  extraNote?: string
  showDollarIcon?: boolean
  LabelFontColor?: string
  LabelColor?: string
  ChipLabelColor?: string
  background?: any
  externalDisplay?: boolean
  footerText?: any
  themeColor?: string
}

const useStyles = makeStyles({
  button: (props: any) => ({
    minWidth: props.options.length > 2 ? '50px' : '126px',
    backgroundColor:
      props.variant !== 'outlined' && !props.disabled
        ? props.themeColor
        : undefined,
    color:
      props.variant === 'outlined' && !props.disabled
        ? props.themeColor
        : '#fff',
    borderColor:
      props.variant === 'outlined' && !props.disabled
        ? props.themeColor
        : undefined,
    '&:hover': {
      backgroundColor:
        props.variant === 'outlined' && !props.disabled
          ? props.hoverBgColor
          : props.themeColor,
      borderColor:
        props.variant === 'outlined' && !props.disabled
          ? props.themeColor
          : undefined,
    },
  }),
})

export const Footer = (props: IFooterProps) => {
  const {
    chipLabel,
    options,
    value,
    isHidden,
    totalAmt,
    extraNote,
    showDollarIcon,
    LabelFontColor,
    ChipLabelColor,
    LabelColor,
    background,
    externalDisplay,
    footerText,
    themeColor,
  } = props
  let single: boolean = false

  if (options?.length === 1) {
    single = true
  }

  const classes = useStyles({ themeColor, options })

  return (
    <div
      className={externalDisplay ? 'externalFooter' : 'footer'}
      id="footer"
      style={{
        zIndex: 999,
        backgroundColor:
          externalDisplay && footerText ? 'transparent' : undefined,
        boxShadow: externalDisplay && footerText ? 'none' : undefined,
      }}
    >
      {footerText}
      {chipLabel && (
        <div
          className="footer-label"
          style={{ color: LabelFontColor, backgroundColor: LabelColor }}
        >
          <div
            className="chip-label"
            style={{ color: LabelFontColor, backgroundColor: ChipLabelColor }}
          >
            {chipLabel}
          </div>

          <span className="label-value">
            {showDollarIcon ? (
              <div
                style={{
                  display: 'inline-flex',
                  marginLeft: 'auto',
                  alignItems: 'center',
                }}
              >
                <MonetizationOnIcon
                  style={{ fontSize: '13px', paddingRight: '5px' }}
                />
                {value}
              </div>
            ) : (
              value
            )}
          </span>
        </div>
      )}
      {totalAmt && (
        <div
          className="footer-label"
          style={{
            background: '#faf2e8',
          }}
        >
          <div className="xsTitle">
            Total: <span className="color-primary-orange">{value}</span>
          </div>
        </div>
      )}
      {extraNote && (
        <div
          className="footer-label"
          style={{
            background: '#faf2e8',
          }}
        >
          <div className="desc text-noflow" style={{ textAlign: 'center' }}>
            <span style={{ color: 'red' }}>{extraNote}</span>
          </div>
        </div>
      )}
      {!isHidden && (
        <div className="footer-btn">
          {options.map((el, index) => (
            <Button
              key={index}
              type="submit"
              color={el.color}
              variant={el.variant || 'contained'}
              className={`${single ? 'single' : ''} ${classes.button}`}
              onClick={el.onClick}
              disabled={el.disabled}
              {...el.props}
            >
              {el.name}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}

export const Filter = (data, filterValue) => {
  return data?.filter(i => {
    if (
      JSON.stringify(i)
        ?.toLowerCase()
        .includes(filterValue.toLowerCase())
    )
      return i
  })
}

export const LeaveAppLeaveRecordFilter = (data, filterValue) => {
  return data?.filter(i => {
    // to remove approvedby property from data
    let startIdx = JSON.stringify(i)
      .toLowerCase()
      .indexOf('"approvedby"')
    let appBySubstr = JSON.stringify(i)
      .toLowerCase()
      .substr(
        startIdx,
        JSON.stringify(i)
          .toLowerCase()
          .indexOf(',', startIdx)
      )
    let trimmedData = JSON.stringify(i)
      .toLowerCase()
      .replace(appBySubstr, '')

    if (trimmedData.includes(filterValue.toLowerCase())) {
      return i
    }
  })
}

